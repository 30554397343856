'use strict'

###*
 # @ngdoc object
 # @name mundoAudit
 # @description

###
angular
  .module 'mundoAudit', [
    'mundoActions'
  ]
  .constant 'mundoAuditPermission', 'access audit logs'
  .run [
    'MundoActions'
    'AuditLogs'
    'UiHelpers'
    (MundoActions, AuditLogs, UiHelpers) ->
      MundoActions.register 'debug_audit_log', (options, action) ->
        AuditLogs.getList
          'filter[]': "_id,eq:id #{options.data.id}"
        .then (result) ->
          if result.length
            UiHelpers.openDialog
              template: 'mundo-actions/views/json-dialog.tpl.html'
              data:
                json: result[0].plain()

      MundoActions.register 'audit_logs', (options, action) ->
        filters = []

        if options.data.object_id?
          filters.push "OR," +
            "actions.data.object.id,eq #{options.data.object_id}," +
            "actions.data.object.parent.id,eq #{options.data.object_id}"

        if not filters
          return

        data =
          items: []
          filters: filters
          order: '-createdAt'
          page: 1
          limit: 10
          limitOptions: [10, 25, 50, 75]
          query:
            action: null
            user: null
            createdAt: null

        data.toggleDetails = (entry) ->
          entry._expanded = not entry._expanded

          if not entry._plain?
              entry._plain = entry.clone().plain()

        data.reload = () ->
          sortField = data.order.replace('-', '')
          sortOrder = if data.order[0] == '-' then 'DESC' else 'ASC'

          filters = angular.copy data.filters

          if data.query.action?
            filters.push "actions.action,LIKE #{data.query.action}"

          if data.query.user?
            filters.push "user.user,LIKE #{data.query.user}"

          if data.query.createdAt?
            date = data.query.createdAt
            date = "#{date.getFullYear()}-#{date.getMonth() + 1}-#{date.getDate()}"
            minDate = "#{date} 00:00:00"
            maxDate = "#{date} 23:59:59"
            filters.push "createdAt,GTE:DT #{minDate}"
            filters.push "createdAt,LTE:DT #{maxDate}"

          AuditLogs.getList
            'filter[]': filters
            limit: data.limit
            offset: data.limit * (data.page - 1)
            sort: "#{sortField},#{sortOrder}"
          .then (results) ->
            data.count = results.count
            data.items = results

        UiHelpers.openDialog
          title: 'app.audit'
          template: 'mundo-audit/views/audit-logs-dialog.tpl.html'
          dialogClass: 'mundo-dialog-lg'
          data: data
          onInitialize: () ->
            data.reload()
  ]
